@import url("https://fonts.googleapis.com/css2?family=Goudy+Bookletter+1911&display=swap");

:root:not([data-theme="light"]) {
  --primary: #c7b198;
  --primary-hover: #c2185b;
  --primary-focus: rgba(216, 27, 96, 0.125);
  --primary-inverse: #000;
  --background-color: #000;
  --color: var(--primary);
  --card-background-color: #000;
  --font-family: "Goudy Bookletter 1911", serif;
  --nav-element-spacing-vertical: 1rem;
  --block-spacing-vertical: 1rem;
  --spacing: 4rem;
}

html {
  background: var(--background-color);
  font-family: "Goudy Bookletter 1911";
}

html,
body {
  width: 100%;
  height: auto;
  padding: 0;
  color: var(--color);
}

main > article {
  margin: 0;
  padding: 0;

  & > section {
    margin-bottom: 0;
  }
}

body > main {
  padding-top: 0;
}

.container-fluid {
  padding-bottom: calc(var(--spacing) / 2);
  @media (max-width: 420px) {
    --spacing: 1.5rem;
    --block-spacing-vertical: 1.5rem;
  }
}

a,
h1 {
  font-size: 22px;
  transition: color 0.3s ease-in-out;
  --typography-spacing-vertical: 1rem;
}

h1 {
  color: var(--primary);
  --color: var(--primary);
}

a {
  color: var(--color);
}

a:hover,
h1:hover {
  color: var(--color);
}

nav > a#comic {
  padding: 10px;
  text-align: center;
  margin-left: -5px;
  width: auto;
  transition: 1s;
  &:hover {
    transform: rotate(360deg);
  }
}

input.slider {
  position: absolute;
  top: 0;
  z-index: 99999;
}

nav {
  align-items: center;
  position: relative;
  z-index: 99999;
  h1,
  a,
  ul {
    width: 100%;
  }
  a,
  h1 {
    margin: 0;
    padding: var(--nav-link-spacing-vertical) var(--nav-link-spacing-horizontal);
    line-height: 100%;
  }
  h1 {
    padding-left: 0;
    padding-right: 0;
    a {
      padding: 0;
      margin: 0;
    }
  }

  ul {
    justify-content: right;
    &:last-of-type {
      margin-right: 0;
      li {
        padding-right: 0;
        a {
          padding-right: 0;
        }
      }
    }
  }
}

#sketches {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 0; // remove gaps between grid items
  margin: 0;
  padding: 0;

  .grid-item {
    width: 50%; // default width and height
    outline: solid 1px var(--primary);
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    cursor: pointer;
    z-index: 1;
    //transition: transform 1s ease-in-out;

    &.is-dragging {
      z-index: 9999;
      transition: none;
    }

    &--width1 {
      width: 25%;
      @media (max-width: 420px) {
        width: 50%;
      }
    } // override default width
    &--width2 {
      width: 50%;
    }
    &--width3 {
      width: 75%;
      @media (max-width: 420px) {
        width: 100%;
      }
    }

    &--height1 {
      height: 25vh;
      min-height: 500px;
      aspect-ratio: 1 / 1;
    } // override default height
    &--height2 {
      height: 50vh;
      @media (max-width: 420px) {
        height: 25vh;
      }
    }

    &.empty {
      &::after {
        content: "sometime soon";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        text-align: center;
        vertical-align: middle;
        font-size: 14px;
        opacity: 0;
        transition: 2s ease-in-out;
      }
      &:hover {
        &::after {
          opacity: 1;
        }
      }
    }

    @media (max-width: 420px) {
      &:nth-child(3),
      &:nth-child(7),
      &:nth-child(14) {
        width: 100%;
      }
    }
  }
}

footer {
  padding-top: calc(var(--spacing) / 2);
  padding-bottom: 0;
  ul {
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;

    li {
      width: 100%;
      list-style-type: none;
      margin: 0;
    }
  }
}
